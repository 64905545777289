import React, {  useState } from 'react';
import { FeatureItem, } from "@/components/monitize/FeaturesList";
import { EntitlementEnum } from "@/openapi";
import { t } from "@/utils/translation";
import { freeBadge, premiumBadge, silverBadge } from "./options";
import InfoIcon from "@/components/icons/InfoIcon";
import Modal from '@/components/misc/Modal';

const _ = t;


interface FeatureBoxProps {
  entitlement: EntitlementEnum;
  features: FeatureItem[];
}

const FeatureBox: React.FC<FeatureBoxProps> = ({ entitlement, features }) => {
  const [modalContent, setModalContent] = useState({ title: '', subtitle: '' });
  const openModal = (feature: FeatureItem) => {
    setModalContent({ title: _(feature.title), subtitle: _(feature.subtitle) });
  };
  
  const closeModal = () => {
    setModalContent({ title: '', subtitle: '' });
  };
  const getBadge = () => {
    const className = "font-normal text-lg px-4 rounded-none w-full ";
    if (entitlement === "PRO") {
      return premiumBadge(className);
    }
    if (entitlement === "BROWSING") {
      return silverBadge(className);
    }
    return freeBadge(className);
  };

  const thisFeatures = features.filter(f => {
    if (entitlement === "PRO") {
      return f.entitlements.length == 1;
    }
    if (entitlement === "BROWSING") {
      return f.entitlements.length == 2;
    }
    return true;

  });
  const getPlusText = () => {
    const plusString = _('plus_text').replace('{entitlement}', entitlement === "PRO" ? 'Silver' : 'Free');
    return <tr>
      <td className="py-2 px-4 text-text-secondary text-sm font-light">{plusString} </td>
    </tr>
  }
  return (
    <div className="flex flex-col justify-center w-80 h-full bg-white rounded-lg overflow-hidden">
      {getBadge()}
      <div className="w-full">
        <table className="min-w-full table-auto border-collapse">
          <tbody>
            {thisFeatures.map((feature, index) => (
              <tr 
              onClick={() => openModal(feature)}
              key={index} className={`${index === features.length - 1 ? '' : 'border-b'}`}>
                <td className="py-2 px-4 flex justify-between items-center gap-2">
                  {_(feature.title)} {InfoIcon({ width: 16, height: 16, stroke: "#666358" })}
                </td>
              </tr>
            ))}
            {entitlement !== "NONE" && (
              getPlusText()
            )}
          </tbody>
        </table>
        <Modal id="uniqueModalId" show={modalContent.title !== ''} onClose={closeModal} title={modalContent.title} body={modalContent.subtitle} />
        </div>
    </div>
  );

};

export default FeatureBox;