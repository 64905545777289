import { EntitlementEnum } from '@/openapi';
import { FeatureItem } from '@/components/monitize/FeaturesList';
import React, { useEffect, useRef, useState } from 'react';
import { t } from "@/utils/translation";
import { freeBadge, premiumBadge, silverBadge } from './options';
import HorseRunning from '@/public/svg/check_icon.svg';
import { entitlementToString } from '@/utils/stringUtils';

// translation function
export const _ = t;


interface FeaturesTableProps {
  features: FeatureItem[];
}


const FeaturesTable: React.FC<FeaturesTableProps> = ({ features }) => {
  const [tooltip, setTooltip] = useState<{ visible: boolean, content: string, x: number, y: number, maxWidth: number }>({ visible: false, content: '', x: 0, y: 0, maxWidth: 0 });
  const containerRef = useRef<HTMLElement>(null);


  const showTooltip = (event: React.MouseEvent, subtitle: string) => {
    if (containerRef.current) {
      const containerBounds = containerRef.current.getBoundingClientRect();
      let x = event.pageX;
      let y = event.pageY;

      let relativeX = x - containerBounds.left;
      // Calculate maximum width based on the space to the right of the cursor
      const maxAvailableWidth = containerBounds.width - relativeX - 12;
      const maxWidth = Math.max(120, Math.min(400, maxAvailableWidth));
      setTooltip({
        visible: true,
        content: subtitle,
        x,
        y,
        maxWidth: maxWidth
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (tooltip.visible) {
        hideTooltip();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [tooltip.visible]);




  const hideTooltip = () => {
    setTooltip(prev => ({ ...prev, visible: false }));
  };

  const getBadgeClassName = (fontColor: string) => {
    return `font-semibold text-base py-0.5 px-2 ${fontColor}`;
  }


  const badgeRowClassName = "text-center py-2 "; // Last removes border on the last column
  const checkClassName = " text-xl border-r py-2 last:border-r-0"; // Apply border-bottom and right, remove last column right border
  const checkIcon = <div className='flex justify-center'>
    <HorseRunning />
  </div>
  return (
    <div ref={containerRef as any} className='bg-white rounded-lg overflow-hidden'>
      {tooltip.visible && (
        <div style={{ top: tooltip.y, left: tooltip.x, position: 'absolute', zIndex: 50, maxWidth: `${tooltip.maxWidth}px` }}
          className="bg-white border border-gray-200 shadow-lg p-2 mt-2 mr-20 font-light text-sm rounded">
          {_(tooltip.content)}
        </div>
      )}
      <table className="min-w-full table-auto border-collapse">
        <thead className="bg-lightest-gray">
          <tr>
            <th className='text-left  align-middle text-base pl-2 font-medium'>Features</th>
            <th className={`${badgeRowClassName} bg-free-background`}><div className={getBadgeClassName('text-dark-brown')}>
              {entitlementToString("NONE")}</div></th>
            <th className={`${badgeRowClassName} bg-browser-background`}><div className={getBadgeClassName('text-dark-brown')}>
              {entitlementToString("BROWSING")}</div></th>
            <th className={`${badgeRowClassName} bg-premium-background`}><div className={getBadgeClassName('text-premium-text')}>
              {entitlementToString("PRO")}</div></th>
          </tr>
        </thead>
        <tbody>
          {features.map((feature, index) => (
            <tr key={index}
              onMouseMove={(e) => showTooltip(e, _(feature.subtitle))}
              onMouseLeave={hideTooltip}>
              <td className={`pl-2 text-sm font-light border-r last:border-r-0 ${index === features.length - 1 ? '' : 'border-b'}`}>{_(feature.title)}</td>
              <td className={`${checkClassName} ${index === features.length - 1 ? '' : 'border-b'}`}>{feature.entitlements.includes("NONE") ? checkIcon : ''}</td>
              <td className={`${checkClassName} ${index === features.length - 1 ? '' : 'border-b'}`}>{feature.entitlements.includes("BROWSING") ? checkIcon : ''}</td>
              <td className={`${checkClassName} pr-2 ${index === features.length - 1 ? '' : 'border-b'}`}>{feature.entitlements.includes("PRO") ? checkIcon : ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


export default FeaturesTable;


