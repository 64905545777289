import { t } from "@/utils/translation";
import Image from "next/image";
const _ = t;

const LandsmotOfferBadge = () => {
  const title = _("landsmot_offer_title");
  const subtitleParts = _("landsmot_offer_subtitle").split(/(LM2024)/);

  return (
    <div className="flex justify-center bg-landsmot-blue-opacity shadow-md rounded-lg items-center w-full py-2 sm:py-1 px-2 sm:px-4 border border-landsmot-blue">
      <div className="sm:mr-8 mr-4 flex-shrink-0">
        <Image
          src="/landsmot_logo.png"
          width={64}
          height={64}
          alt="Landsmot logo"
        />
      </div>
      <div className="flex flex-col sm:items-center sm:justify-center">
        <span className="sm:text-xl">{title}</span>
        <span className="sm:text-sm text-xs text-text-secondary">
          {subtitleParts.map((part, index) => (
            part === "LM2024" ? <strong key={index}>{part}</strong> : part
          ))}
        </span>
      </div>
    </div>
  );
};


export default LandsmotOfferBadge;