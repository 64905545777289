import { useAuth } from "@/auth";
import Button from "@/components/buttons/Button";
import SwitchWidget from "@/components/buttons/SwitchWIdget";
import CheckoutLayout from "@/components/checkoutLayout";
import Checkbox from "@/components/inputs/Checkbox";
import TextInput from "@/components/inputs/TextInput";
import Spinner from "@/components/misc/Spinner";
import FeaturesList, {
  FeatureItemComponent,
  getAllFeatures,
  getFeatures,
} from "@/components/monitize/FeaturesList";
import {
  DurationEnum,
  EntitlementEnum,
  SimpleUser,
  StripePricesAndProducts,
  User,
} from "@/openapi";
import { setProduct } from "@/redux/reducers/paymentReducer";
import { IStore } from "@/redux/store";
import { getStringFromList, stripeApi } from "@/utils/NetworkUtils";
import { trackEvent } from "@/utils/analyticsFunctions";
import { captureException, devPrint } from "@/utils/devUtils";
import {
  IStripeProductPrice,
  durationToReadable,
  getActiveProductsFromPricesProducts,
  getGiftcardProduct,
  getPaymentReturnCancelUrl,
  getPaymentReturnSuccessUrl,
  getProductTitle,
  getStripePublishableKey,
  priceToReadable,
} from "@/utils/paymentUtils";
import { getIPCountry } from "@/utils/userUtils";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { _ } from "../_app";
import {
  durationToBillingCycle,
  entitlementToString,
} from "@/utils/stringUtils";
import { clsx } from "clsx";
import RadioBox from "@/components/buttons/RadioBox";
import TextContainer from "@/components/containers/TextContainer";
import { dateToReadable } from "@/utils/dateUtils";
import { addDays, addMonths, addYears, format } from "date-fns";
import DropdownButton from "@/components/buttons/DropdownButton";
import PremiumBadge from "@/components/misc/PremiumBadge";
import CloseButton from "@/components/buttons/CloseButton";
import OptionsCard from "./options";
import FeaturesTable from "./FeatureTable";
import FeatureBox from "./FeatureBox";
import SelectedProductDialog from "./SelectedProductDialog";
import LandsmotOfferBadge from "./LandsmotOfferBadge";

const getDefaultProduct = (pricesProducts?: StripePricesAndProducts) => {
  if (!pricesProducts) {
    return undefined;
  }

  var items = getActiveProductsFromPricesProducts(pricesProducts);
  for (var item of items) {
    if (
      item.product.metadata.duration === "YEAR" &&
      item.product.metadata.entitlement === "PRO"
    ) {
      return item;
    }
  }
  return undefined;
};

interface ICheckoutPage {
  user: User | undefined;
  pricesProducts?: StripePricesAndProducts;
  setProduct: (product: IStripeProductPrice | undefined) => void;
}

const CheckoutPage = ({ user, ...props }: ICheckoutPage) => {
  const stripe = useStripe();
  console.log("stripe", stripe);
  const router = useRouter();

  const elements = useElements();
  const [selectedProduct, setSelectedProduct] = useState(
    getDefaultProduct(props.pricesProducts)
  );
  const [isMobile, setIsMobile] = useState(false);

  const checkScreenSize = () => {
    setIsMobile(window.innerWidth < 768); // Assuming 768px is the breakpoint for mobile
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    // setSelectedProduct(getDefaultProduct(props.pricesProducts));
  }, [props.pricesProducts]);

  const [buyForUser, setBuyForUser] = useState(
    undefined as undefined | SimpleUser
  );
  const [buyAsGiftcard, setBuyAsGiftcard] = useState(false);
  const [buyerEmail, setBuyEmail] = useState("");

  const [errorMessage, setErrorMessage] = useState(
    undefined as undefined | string
  );

  const { giftcard: _giftcard } = router.query;
  const [giftcard, setGiftcard] = useState(getStringFromList(_giftcard) ?? "");
  const [showRedeemGiftcard, setShowRedeemGiftcard] = useState(
    giftcard.length > 0
  );

  devPrint(_giftcard);

  const [country, setCountry] = useState(undefined);
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [loadingCheckoutGitfcard, setLoadingCheckoutGiftcard] = useState(false);
  const [duration, setSelectedDuration] = useState(
    "YEAR" as "MONTH" | "YEAR" | undefined
  );
  useEffect(() => {
    getIPCountry().then((v) => {
      setCountry(v);
    });
  }, []);

  const handleRedeeGiftcard = async () => {
    if (!user) {
      router.push("/login");
      return;
    }
    if (!giftcard) {
      toast.error("Please enter the giftcard code.");
      return;
    }
    setErrorMessage(undefined);
    if (!stripe) {
      setErrorMessage("Stripe not defined");
      return;
    }
    try {
      trackEvent("redeeme_giftcard");
      setLoadingCheckoutGiftcard(true);
      const res = await stripeApi.stripeRedeemGiftcardCreate({
        coupon: giftcard,
        successUrl: getPaymentReturnSuccessUrl(),
        cancelUrl: getPaymentReturnCancelUrl(),
      });

      stripe
        .redirectToCheckout({
          sessionId: res.id,
        })
        .then(function (result) {
          if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer.
            setErrorMessage(result.error.message);
          }
        });
    } catch (e) {
      captureException(e, true);
    }
    window.setTimeout(() => {
      setLoadingCheckoutGiftcard(false);
    }, 1000);
  };

  const handleBuy = async (product: IStripeProductPrice) => {
    if (!user && !buyAsGiftcard) {
      props.setProduct(product);
      router.push("/login");
      return;
    }

    // if buy gift card, find gift card product, that is a subscription
    // but the product will be a payment.
    let giftcardProduct: IStripeProductPrice | undefined = undefined;
    if (buyAsGiftcard) {
      var data = getGiftcardProduct(product, props.pricesProducts!);
      if (!data.product || !data.giftcardProduct) {
        toast.error("An error occured, product not found");
        return;
      }
      product = data.product!;
      giftcardProduct = data.giftcardProduct;
    }

    setErrorMessage(undefined);
    if (!stripe) {
      setErrorMessage("Stripe not defined");
      return;
    }
    try {
      setLoadingCheckout(true);
      trackEvent("handle_buy", {
        product_name: product.product.name,
        duration: product.product.metadata.duration,
        entitlement: product.product.metadata.entitlement,
      });
      const res = await stripeApi.stripeCheckoutSessionCreate({
        cancelUrl: getPaymentReturnCancelUrl(product),
        successUrl: getPaymentReturnSuccessUrl(product, buyAsGiftcard),
        priceId: product.price.id,
        coupon: "",
        clientReferenceId: user?.id.toString(),
        buyAsGiftcard: buyAsGiftcard,
        buyerEmail: buyerEmail ?? user?.email,
        giftcardPriceId: giftcardProduct?.price?.id,
      });

      // When the customer clicks on the button, redirect
      // them to Checkout.
      stripe
        .redirectToCheckout({
          sessionId: res.id,
        })
        .then(function (result) {
          if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer.
            setErrorMessage(result.error.message);
          }
        });
    } catch (e) {
      captureException(e, true);
    }
    window.setTimeout(() => {
      setLoadingCheckout(false);
    }, 1000);
  };

  const renderGiftcardInfo = () => {
    const moreInfoText = _(
      "The giftcard needs to be redeemed here. The subscription won't take effect until the giftcard is redeemed."
    );

    return (
      <div>
        <div>{_("You will recieve the giftcard via email.")}</div>
        <div className="text-sm mt-4 text-stone-gray">{moreInfoText}</div>
      </div>
    );
  };

  const renderRedeemGiftcard = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          devPrint("sub");
        }}
        className="shadow-sm rounded-md bg-white p-4 mt-4 m-auto"
        style={{ minWidth: 250, maxWidth: 400 }}
      >
        {showRedeemGiftcard && (
          <TextInput
            value={giftcard}
            className="border"
            placeholder="Write the giftcard code"
            onChange={(v) => {
              setGiftcard(v);
            }}
            onEnter={() => {
              devPrint("enter");
              handleRedeeGiftcard();
            }}
          />
        )}
        <Button
          className="mb-0 m-auto block "
          loading={loadingCheckoutGitfcard}
          onClick={() => {
            if (!showRedeemGiftcard) {
              setShowRedeemGiftcard(true);
            } else {
              handleRedeeGiftcard();
            }
          }}
        >
          {_("Redeem giftcard")}
        </Button>
      </form>
    );
  };

  const premiumBadge = (className?: string) => {
    return (
      <TextContainer
        color={"bg-premium-background text-premium-text"}
        className={className}
      >
        {entitlementToString("PRO")}
      </TextContainer>
    );
  };

  const silverBadge = (className?: string) => {
    return (
      <TextContainer
        color={"bg-browser-background text-dark-brown"}
        className={className}
      >
        {entitlementToString("BROWSING")}
      </TextContainer>
    );
  };

  const freeBadge = (className?: string) => {
    return (
      <TextContainer
        color={"bg-free-background text-dark-brown"}
        className={className}
      >
        {entitlementToString("NONE")}
      </TextContainer>
    );
  };

  const renderSubscriptionOptions = () => {

    if (!props.pricesProducts) {
      return (
        <div>
          <Spinner className="flex flex-row justify-center pt-4" />
        </div>
      );
    }
    const features = getAllFeatures();
    const entitlementValues: EntitlementEnum[] = ["NONE", "BROWSING", "PRO"];
    const optionClassName = `${isMobile ? 'w-80 mx-auto' : 'flex-1'}`;

    const now = +new Date();
    const then = +new Date(2024, 7, 8);
    const showBanner = now < then;
    return (
      <div className="">
        <div className="bg-eggshell-grayer">
          <div style={{ maxWidth: 1000 }} className=" mx-auto px-6 pb-8">
            {showBanner && (
              <div className="px-2 pt-4">{LandsmotOfferBadge()}</div>
            )}
            <div className=" pt-6 pb-8 text-center text-2xl sm:text-3xl font-medium">
              {_("checkout-page_title")}
            </div>
            <div className={`flex ${isMobile ? 'flex-col gap-6' : 'md:flex-row lg:flex-row xl:flex-row 2xl:flex-row flex-col sm:flex-col gap-12'}  px-2`}>
              <div className={optionClassName} >
                <OptionsCard
                  entitlement="BROWSING"
                  description={_("sub-browsing-description")}
                  pricesProducts={props.pricesProducts}
                  user={user}
                  setSelectedProduct={setSelectedProduct}
                />
              </div>
              <div className={optionClassName} >
                <OptionsCard
                  entitlement="PRO"
                  description={_("sub-pro-description")}
                  pricesProducts={props.pricesProducts}
                  user={user}
                  setSelectedProduct={setSelectedProduct}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ maxWidth: 1000 }} className="mx-auto">
          <div className=" pt-6 mx-2 px-6 pb-6">
            <div className="text-center mb-4 text-text-secondary  text-lg">
              {_("checkout-features_title")}
            </div>
            {isMobile ? (
              <div className="flex flex-col mt-6 mx-2 gap-4 items-center">
                {entitlementValues.map((entitlement) => (
                  <FeatureBox
                    key={entitlement}
                    entitlement={entitlement}
                    features={features.filter(feature => feature.entitlements.includes(entitlement))}
                  />
                ))}
              </div>
            ) : (
              <div>
                <FeaturesTable features={features} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="">
      {renderSubscriptionOptions()}
      {selectedProduct && (
        <SelectedProductDialog
          selectedEntitlement={selectedProduct.product.metadata.entitlement}
          allProducts={getActiveProductsFromPricesProducts(props.pricesProducts!)}
          setSelectedProduct={setSelectedProduct}
          loadingCheckout={loadingCheckout}
          handleBuy={handleBuy}
        />
      )}
      {/* {!selectedProduct && renderRedeemGiftcard()} */}
    </div>
  );
};

const stripePromise = loadStripe(getStripePublishableKey());

interface ICheckoutPageContainer {
  user: User | undefined;
  setProduct: (product: IStripeProductPrice | undefined) => void;
}

const CheckoutPageContainer = (props: ICheckoutPageContainer) => {
  const [pricesProducts, setPP] = useState(
    undefined as StripePricesAndProducts | undefined
  );
  const router = useRouter();
  const { loading, isAuthenticated, login } = useAuth();

  useEffect(() => {
    stripeApi
      .stripePricesAndProductsRetrieve()
      .then((e) => {
        setPP(e);
      })
      .catch((e) => {
        captureException(e);
      });
  }, [props.user]);

  return (
    <Elements stripe={stripePromise}>
      <CheckoutPage
        user={props.user}
        pricesProducts={pricesProducts}
        setProduct={props.setProduct}
      />
    </Elements>
  );
};

CheckoutPageContainer.getLayout = (page: JSX.Element) => {
  return <CheckoutLayout className="">{page}</CheckoutLayout>;
};
const mapStateToProps = (state: IStore) => {
  const data = state.userState;

  const { user } = data;
  return { user };
};

const mapDispatchToProps = (dispatch: any) => ({
  setProduct: (horse: IStripeProductPrice | undefined) =>
    dispatch(setProduct(horse)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutPageContainer);
