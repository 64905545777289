import { IStripeProductPrice, priceToReadable } from "@/utils/paymentUtils";
import { premiumBadge, silverBadge, freeBadge } from "./options";
import RadioBox from "@/components/buttons/RadioBox";
import { _ } from "../_app";
import { DurationEnum, EntitlementEnum } from "@/openapi";
import { useState } from "react";
import { addDays, addMonths, addYears, format } from "date-fns";
import TextContainer from "@/components/containers/TextContainer";
import { durationToBillingCycle, entitlementToString } from "@/utils/stringUtils";
import Button from "@/components/buttons/Button";
import CloseIcon from '@/public/svg/close_dialog.svg';
import Image from "next/image";


interface SelectedProductDialogProps {
  selectedEntitlement: EntitlementEnum;
  allProducts: IStripeProductPrice[];
  setSelectedProduct: (product: IStripeProductPrice | undefined) => void;
  loadingCheckout: boolean;
  handleBuy: (product: IStripeProductPrice) => void;
}

const SelectedProductDialog: React.FC<SelectedProductDialogProps> = (props: SelectedProductDialogProps) => {
  const [duration, setSelectedDuration] = useState(
    "YEAR" as "MONTH" | "YEAR"
  );
  const entitlement = props.selectedEntitlement;
  const yearProduct = props.allProducts.find(product => product.product.metadata.duration === "YEAR" && product.product.metadata.entitlement === entitlement);
  const monthProduct = props.allProducts.find(product => product.product.metadata.duration === "MONTH" && product.product.metadata.entitlement === entitlement);
  const selectedProduct = duration === "MONTH" ? monthProduct : yearProduct;
  const getBadge = () => {
    const className = "font-normal text-center text-lg px-4 rounded-none w-full ";
    if (entitlement === "PRO") {
      return premiumBadge(className);
    }
    if (entitlement === "BROWSING") {
      return silverBadge(className);
    }
    return freeBadge(className);
  };

  const renderSummary = () => {

    const now = +new Date();
    const then = +new Date(2024, 7, 8);
    const showBanner = now < then;
    var renewsDate = duration === "YEAR" ? addYears(now, 1) : addMonths(now, 1);
    // renewsDate = addDays(renewsDate, 14);
    return (
      <div className="mt-4">
        <div className="mt-2 text-sm font-semibold text-text-secondary mb-2">{_("checkout-summary")}</div>

        <hr className="mt-2 mb-2" />

        <div className="flex flex-row justify-between">
          <div className="font-semibold font">{_("Due")}</div>
          <div className="font-semibold">
            {priceToReadable(
              selectedProduct?.price.unitAmount ?? 0,
              selectedProduct?.price.currency ?? ""
            )}
          </div>
        </div>
        <div className="font-light text-sm">
          {_("Renews")} {format(renewsDate, "d. MMM, yyyy")}
        </div>
        {showBanner && renderLandsmotOffer()}
        <div className="w-full">
          <Button
            loading={props.loadingCheckout}
            className="mt-6 w-full"
            onClick={() => {
              props.handleBuy(selectedProduct!);
            }}
          >
            {_("Continue to checkout")}
          </Button>
        </div>
      </div>
    );
  };

  const renderLandsmotOffer = () => {
    const discountedPrice = (yearProduct?.price.unitAmount !== undefined ? yearProduct?.price.unitAmount * 0.8 : 0);
    const discountedPriceReadable = priceToReadable(discountedPrice, yearProduct?.price.currency ?? "");
    if (duration === "YEAR") {
      const subtitleParts = _("landsmot-offer-checkout").split(/(LM2024)/);
      return (
        <div className="bg-landsmot-blue-opacity rounded-md flex mt-2 px-2 py-2 items-center justify-between">
          <div className="text-sm font-medium text-text-secondary">{subtitleParts.map((part, index) => (
            part === "LM2024" ? <strong key={index}>{part}</strong> : part
          ))}</div>
          <div className="font-semibold">{discountedPriceReadable}</div>
        </div>
      );
    } else {
      return (
        <div className="bg-landsmot-blue-opacity rounded-md flex flex-col mt-2 px-2 py-2" >
          <div className="flex items-center">
            <div className="hidden sm:flex mr-3 flex-shrink-0">
              <Image
                src="/landsmot_logo.png"
                width={38}
                height={38}
                alt="Landsmot logo"
              />
            </div>
            <div className="flex flex-col">
              <div className="text-sm font-semibold text-text-secondary">{_("landsmot_offer_title")}</div>
              <div className="text-sm font-light">{_('landsmot_offer_upgrade')} {discountedPriceReadable}
              </div></div>
          </div>
        </div>
      );
    }
  }

  const getDurationBox = (d: DurationEnum) => {
    const selected = duration === d;
    const dProduct = d === "MONTH" ? monthProduct : yearProduct;
    const label = _(d + '-duration-label');
    const onChange = () => {
      if (d === "MONTH") {
        props.setSelectedProduct(monthProduct);
      } else {
        props.setSelectedProduct(yearProduct);
      }
      setSelectedDuration(d);
    }
    return (
      <div
        className={`flex-1 rounded-md border-2 p-3 ${selected ? 'border-primary' : 'border-gray-300'} cursor-pointer min-w-full`}
        onClick={onChange}
      >
        <div className="flex items-center justify-between font-semibold">
          {/* {renderCircle()} */}
          {_(d + '-duration-description')}
          <span className="text-xs font-semibold  text-worldfengur-green ml-2">{label}</span>
        </div>

        <div className="sm:mt-2 text-sm font-base">
          {priceToReadable(
            dProduct?.price.monthPrice ?? 0,
            dProduct?.price.currency ?? ""
          )}{""}
          /{_("price-month")}
        </div>
      </div>
    );
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center px-4">
      <div className="bg-white rounded-lg overflow-hidden relative w-[320px] sm:w-[520px]">
        <div className="flex justify-center">
          {getBadge()}
          <button
            onClick={() => props.setSelectedProduct(undefined)}
            className="absolute right-0 top-0 translate-y-[-10%]"
            style={{ margin: '10px' }}
            aria-label="Close"
          >
            <CloseIcon />
          </button>
        </div>

        <div className="px-4 py-3">
          <div className="mt-2 text-sm font-semibold text-text-secondary mb-2">{_("Billing cycle")}</div>
          <div className="flex ">
            <div className=" grid grid-cols-1 sm:grid-cols-2 gap-4 min-w-full">
              {getDurationBox("YEAR")}
              {getDurationBox("MONTH")}
            </div>
          </div>
          {renderSummary()}
        </div>
      </div>
    </div>
  );
}

export default SelectedProductDialog;